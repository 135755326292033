import instaphoto from './prophotos/instagram.png';
import satisfaction from './prophotos/satisfaction.png';
import portrait from './prophotos/IMG_6599.jpeg';
import checked from './prophotos/acceptb.png';
import greenCheck from './prophotos/accept.png';
import menu from './prophotos/menu.svg';
import close from './prophotos/close.svg';
import animationData from './animation_lksxakfb.json';
import photo1 from './prophotos/IMG_0051.jpeg';
import photo2 from './prophotos/IMG_0052.jpeg';
import photo3 from './prophotos/IMG_0053.jpeg';
import photo4 from './prophotos/IMG_0054.jpeg';
import photo5 from './prophotos/IMG_0055.jpeg';
import photo6 from './prophotos/IMG_0056.jpeg';
import photo7 from './prophotos/IMG_0057.jpeg';
import photo8 from './prophotos/E3D84A4F-031A-46B7-B17D-CE1FF5C87F22.jpg';
import photo9 from './prophotos/IMG_0760.jpg';
import photo10 from './prophotos/IMG_1517.jpg';
import photo11 from './prophotos/IMG_1538.jpg';

const testimonials = [
    {
        text: "Came in with my PORSHE Cayenne that I was ready to sell. He did a great job especially for the pricing. Returned it looking better than ever! Would highly recommend and will be coming back with my daily as well. He doesn’t just sell his service but himself as well!",
        name: 'AMADEUS COLEMAN'
    },
    {
        text: "Had my Jeep detailed both the interior and exterior with dog hair. Vinny did an AMAZING job, it 100% looks better than when I had originally gotten it years ago. Definitely recommend, I knew my car was in the right hands, I will be back without hesitation.",
        name: 'KYMONIE W'
    },
    {
        text: "I took in my long overdue Jetta for a detail and man was I impressed. I got my car back like as if it was new from the lot. The business owner Vinny is very professional and passionate about the work that is being done. From a business aspect, quotes and prices on different options were simple and affordable for the quality of work. If y’all get the chance to stop by and use Down to the Details you should definitely do so!",
        name: 'BRANDON CARO'
    },
];
const navLinks = [
    {
        id: "services",
        title: "SERVICES",
    },
    {
        id: "about",
        title: "ABOUT US",
    },
    {
        id: "quote",
        title: "QUOTE",
    },
    {
        id: "footer",
        title: "CONTACT"
    }
];

const interiorServices = {
    gold: [
        { name: 'Full Interior Vacuum' },
        { name: 'All Seats Vacuumed and Wiped Down' },
        { name: 'Dash, Center Console, Door Panels Cleaned and Disinfected' },
        { name: 'All Windows Cleaned and Polished' },
        { name: 'AC Vents Cleaning' },
        { name: 'Floor Mats Extracted Cleaned/Shampooed' },
        { name: 'Steam Cleaning Treatment' },
        { name: 'Full Interior Dust/Wipe Down/Disinfect' },
        { name: 'Stain Spot Treatment Throughout Vehicle' },
    ],
    silver: [
        { name: 'Full Interior Vacuum' },
        { name: 'All Seats Vacuumed and Wiped Down' },
        { name: 'Dash, Center Console, Door Panels Cleaned and Disinfected' },
        { name: 'All Windows Cleaned and Polished' },
        { name: 'AC Vents Cleaning' },
        { name: 'Floor Mats Extracted Cleaned/Shampooed' },
    ],
    bronze: [
        { name: 'Full Interior Vacuum' },
        { name: 'All Seats Vacuumed and Wiped Down' },
        { name: 'Dash, Center Console, Door Panels Cleaned and Disinfected' },
        { name: 'All Windows Cleaned and Polished' },
    ],
};

const exteriorServices = {
    gold: [
        { name: 'Foam Cannon Hand Wash/Dry' },
        { name: 'Tires and Rims Cleaned/Polished' },
        { name: 'Windows Cleaned' },
        { name: 'Bug/Tar Removal' },
        { name: 'Spray Wax/Hand Polish for Glossy Finish + Protect the Paint' },
        { name: 'Tire Dressing Applied' },
        { name: 'Plastic Shine Restorer' },
        { name: 'Paint Decontamination/Clay Bar Treatment' },
        { name: 'Compressed Air Dry' },
    ],
    silver: [
        { name: 'Foam Cannon Hand Wash/Dry' },
        { name: 'Tires and Rims Cleaned/Polished' },
        { name: 'Windows Cleaned' },
        { name: 'Bug/Tar Removal' },
        { name: 'Spray Wax/Hand Polish for Glossy Finish + Protect the Paint' },
        { name: 'Tire Dressing Applied' },
    ],
    bronze: [
        { name: 'Foam Cannon Hand Wash/Dry' },
        { name: 'Tires and Rims Cleaned/Polished' },
        { name: 'Windows Cleaned' },
        { name: 'Bug/Tar Removal' },
    ],
};

const interiorServicesTwo = [
    { name: 'Full Interior Vacuum' },
    { name: 'All Seats Vacuumed and Wiped Down' },
    { name: 'Dash, Center Console, Door Panels Cleaned and Disinfected' },
    { name: 'All Windows Cleaned and Polished' },
    { name: 'AC Vents Cleaning' },
    { name: 'Floor Mats Extracted Cleaned/Shampooed' },
    { name: 'Full Interior Dust/Wipe Down/Disinfect' },
    { name: 'Stain Spot Treatment Throughout Vehicle' },
    { name: 'Steam Cleaning Treatment' },
];

const exteriorServicesTwo = [
    { name: 'Foam Cannon Hand Wash/Dry' },
    { name: 'Tires and Rims Cleaned/Polished' },
    { name: 'Windows Cleaned' },
    { name: 'Bug/Tar Removal' },
    { name: 'Spray Wax/Hand Polish for Glossy Finish + Protect the Paint' },
    { name: 'Tire Dressing Applied' }, // Repeated in silver
    { name: 'Plastic Shine Restorer' },
    { name: 'Paint Decontamination/Clay Bar Treatment' },
    { name: 'Compressed Air Dry' },
];


const quoteConfig = {
    Gold: {
        car: {
            full: 246,
            interior: 198,
            exterior: 180,
        },
        suv: {
            full: 264,
            interior: 210,
            exterior: 192,
        },
        truck: {
            full: 282,
            interior: 222,
            exterior: 204,
        },
    },
    Silver: {
        car: {
            full: 228,
            interior: 180,
            exterior: 168,
        },
        suv: {
            full: 246,
            interior: 192,
            exterior: 180,
        },
        truck: {
            full: 264,
            interior: 204,
            exterior: 192,
        },
    },
    Bronze: {
        car: {
            full: 210,
            interior: 168,
            exterior: 156,
        },
        suv: {
            full: 228,
            interior: 180,
            exterior: 168,
        },
        truck: {
            full: 246,
            interior: 192,
            exterior: 180,
        },
    },
};

const images = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
]

export {
    instaphoto,
    satisfaction,
    portrait,
    checked,
    greenCheck,
    menu,
    close,
    animationData,
    testimonials,
    navLinks,
    interiorServices,
    exteriorServices,
    interiorServicesTwo,
    exteriorServicesTwo,
    quoteConfig,
    images,
};